@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
body {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
img {
  max-width: 100%;
}
.banner {
  margin: 10px 0px;
  a {
    display: block;
    img {
      width: 100%;
    }
  }
}
.header-banner {
  background: rgba(0, 0, 0, 0.3);
  a {
    display: block;
  }
}
.nav-header-container {
  background: url(../images/banner-bg.jpg) no-repeat;
  background-size: 100%;
}
.nav-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  .nav-toggle {
    .navbar-toggler {
      border: 0;
      padding: 0;
      .navbar-toggler-icon {
        width: 25px;
        height: 25px;
        background-image: url(../images/hamburger.svg);
      }
    }
    .txt {
      font-size: 18px;
      color: #fff;
      padding: 0 5px;
    }
  }
  .add-post-btn {
    display: inline-flex;
    align-items: center;
    font-size: 17px;
    color: #fff;
    padding: 0 5px;
    text-decoration: none;
    img {
      width: 26px;
      margin-right: 5px;
    }
  }
}
.top-header {
  .top-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-col {
      display: flex;
      align-items: center;
      .social-media-list {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          margin-right: 12px;
          a {
            display: inline-block;
            img {
              width: 18px;
              max-height: 18px;
            }
          }
        }
      }
      .theme-btn {
        padding: 0;
        img {
          width: 25px;
        }
      }
    }
    .right-col {
      display: flex;
      align-items: center;
      .auth-link {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 10px;
        border-radius: 0;
        color: #000;
      }
      .btn-dark {
        font-size: 12px;
        font-weight: 700;
        padding: 5px 10px;
        border-radius: 0;
      }
      .search-btn {
        padding: 0.375rem 0.125rem;
        margin-left: 0.3125rem;
        img {
          width: 16px;
        }
      }
      .cart-btn {
        position: relative;
        padding: 5px;
        img {
          width: 24px;
        }
        span {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 11px;
          color: #111;
        }
      }
    }
  }
}
.page-header {
  position: sticky;
  top: -110px;
  z-index: 1;
  background: #fff;
  .banner {
    img {
      height: 100px;
    }
  }
  .navbar {
    background: #222;
    padding: 0;
    .navbar-brand {
      margin: 0;
      img {
        width: 50px;
      }
    }
    .header-image {
      flex: 1;
      padding: 0 30px;
    }

    .navbar-nav {
      .nav-link {
        display: block;
        padding: 15px 22px 15px 20px;
        font-size: 14px;
        color: #ffffff;
        font-weight: 700;
        background: #444;
        border-bottom: 2px solid #222;
        &.active {
          background: #222;
        }
      }
    }
  }
}
section {
  overflow: hidden;
}
.page-container {
  padding: 15px 0;
}
.section-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &.bordered {
    padding: 5px 0;
    border-bottom: 1px solid #d9d9d9;
  }
  h2 {
    font: 20px "Times New Roman", Times, serif;
    margin: 0;
  }
  .right-col {
    margin-left: auto;
  }
}
.image-news-card {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 -100px 88px #000000f2;
  }
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 4px;
  }
  .content {
    position: absolute;
    bottom: 0;
    padding: 10px;
    p {
      margin: 0;
      font-size: 20px;
      color: #fff;
      font-weight: 800;
    }
  }
}
.news-card {
  overflow: hidden;
  border-bottom: 2px solid gray;
  .news-poster {
    img {
      height: 300px;
      display: block;
      margin: 0 auto;
    }
  }

  .news-card-body {
    padding: 15px 0;
    h3 {
      font: 800 20px "Times New Roman", Times, serif;
      color: #000;
      margin-bottom: 5px;
      line-height: 1.35;
    }
    .time {
      font-size: 14px;
      color: #444;
      a {
        text-decoration: none;
        color: inherit;
      }
      .highlighter {
        color: red;
        font-weight: bold;
        font-size: 15px;
      }
      small {
        display: block;
      }
    }
    .content {
      margin: 15px 0 23px;

      p {
        font-size: 18px;
        color: #444;
        margin-bottom: 10px;
      }
    }
    .card-link {
      color: red;
      font: bold 16px "Times New Roman", Times, serif;
      text-decoration: none;
    }
  }
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
}
.bottom-loader {
  text-align: center;
  margin: 10px 0;
}
.skeleton-loader {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 3px;
  background: #e4e4e4;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(
      to right,
      #e2e2e2 25%,
      #d5d5d5 50%,
      #e2e2e2 100%
    );
    animation: 1.5s gradient-animation infinite;
  }
}
.pwa-install-modal {
  position: fixed;
  display: flex;
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 9;
  padding: 10px;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.2);
  .logo {
    width: 50px;
  }
  .content-block {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    h3 {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 700;
    }
  }
}
@keyframes gradient-animation {
  0% {
    left: -10%;
  }
  to {
    left: 110%;
  }
}
@import "./homepage.scss";
