.top-stories-section {
  padding: 15px 0;
  .slick-slider {
    margin: 0 -15px;
  }
  .slick-slide {
    padding: 0 15px !important;
  }
  .slick-arrow {
    position: absolute;
    top: 0;
    right: 15px;
    left: auto;
    border-radius: 30px;
    transform: translateY(-170%);
    width: 20px;
    height: 20px;
    &.slick-disabled {
      opacity: 0.5;
    }
    &::before {
      content: none;
    }
    &.slick-prev {
      right: 40px;
      background: url(../images/left_arrow.svg);
    }
    &.slick-next {
      background: url(../images/right_arrow.svg);
    }
  }
}
.more-new-section {
  padding: 15px 0;
  .infinite-scroll-component {
    overflow: hidden !important;
  }
}
